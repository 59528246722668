.x1-7 {
  align-items: center;
  background-color: var(--bridal-heath);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 388px;
  padding: 30px 0;
  width: 416px;
}

.this-software-is-tr {
  letter-spacing: 0;
  line-height: 28px;
  margin-right: 20.0px;
  min-height: 196px;
  width: 336px;
}

.img-text-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 100px;
  width: 356px;
}

.line-242-1 {
  height: 2px;
  margin-top: -2px;
  width: 356px;
}

.text-16 {
  align-items: center;
  display: flex;
  gap: 15px;
  min-width: 266px;
}

.image-1 {
  height: 70px;
  width: 70px;
}

.text-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 4.0px;
  min-height: 58px;
  width: 185px;
}

.name {
  letter-spacing: 0;
  line-height: 26px;
  white-space: nowrap;
}

.flex-row-3 {
  align-items: center;
  display: flex;
  gap: 27px;
  height: 26px;
  min-width: 181px;
}

.tutor {
  letter-spacing: 0;
  line-height: 26px;
  min-width: 46px;
  white-space: nowrap;
}

.favourite-31-1 {
  height: 18px;
  width: 108px;
}

.x1-7.x2-6 {
  gap: 4px;
  left: 1032px;
  position: absolute;
  top: 308px;
}

.x1-7.x2-6 .this-software-is-tr {
  min-height: 224px;
}

.x1-7.x2-6 .flex-row-3 {
  gap: 16px;
}

.x1-7.x2-6 .tutor {
  min-width: 57px;
}

