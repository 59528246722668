.total-invoice {
  background-color: var(--white);
  border-radius: 12.93px;
  box-shadow: 0px 2.16px 32.33px #00000014;
  height: 97px;
  left: 303px;
  position: absolute;
  top: 78px;
  width: 216px;
}

