.charts-custom {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 83px;
  justify-content: space-between;
  margin-top: 1.0px;
  position: relative;
  width: 161px;
}

.x-line {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
}

.x1-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
  width: 100.34px;
}

.rectangle {
  align-self: stretch;
  background: linear-gradient(180deg, rgb(237, 222, 93) 0%, rgb(240, 152, 25) 100%);
  height: 7.08px;
  min-width: 100.34px;
  position: relative;
}

.x3-section {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
}

.rectangle-1 {
  align-self: stretch;
  background-color: var(--black-haze);
  border-radius: 0px 59.03px 59.03px 0px;
  height: 7.08px;
  min-width: 60.21px;
  position: relative;
}

.x1-section-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
  width: 33.05px;
}

.rectangle-2 {
  align-self: stretch;
  background: linear-gradient(180deg, rgb(237, 222, 93) 0%, rgb(240, 152, 25) 100%);
  height: 7.08px;
  min-width: 33.05px;
  position: relative;
}

.rectangle-3 {
  align-self: stretch;
  background-color: var(--black-haze);
  border-radius: 0px 59.03px 59.03px 0px;
  height: 7.08px;
  min-width: 127.49px;
  position: relative;
}

.x-line-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  overflow: hidden;
  position: relative;
}

.x1-section-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
  width: 17.71px;
}

.rectangle-4 {
  align-self: stretch;
  background: linear-gradient(180deg, rgb(237, 222, 93) 0%, rgb(240, 152, 25) 100%);
  height: 7.08px;
  min-width: 17.71px;
  position: relative;
}

.rectangle-5 {
  align-self: stretch;
  background-color: var(--black-haze);
  border-radius: 0px 59.03px 59.03px 0px;
  height: 7.08px;
  min-width: 142.84px;
  position: relative;
}

.x1-section-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
  width: 9.44px;
}

.rectangle-6 {
  align-self: stretch;
  background: linear-gradient(180deg, rgb(237, 222, 93) 0%, rgb(240, 152, 25) 100%);
  height: 7.08px;
  min-width: 9.44px;
  position: relative;
}

.rectangle-7 {
  align-self: stretch;
  background-color: var(--black-haze);
  border-radius: 0px 59.03px 59.03px 0px;
  height: 7.08px;
  min-width: 151.1px;
  position: relative;
}

.x1-section-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
  width: 1.18px;
}

.rectangle-8 {
  align-self: stretch;
  background: linear-gradient(180deg, rgb(237, 222, 93) 0%, rgb(240, 152, 25) 100%);
  height: 7.08px;
  min-width: 1.18px;
  position: relative;
}

.rectangle-9 {
  align-self: stretch;
  background-color: var(--black-haze);
  border-radius: 0px 59.03px 59.03px 0px;
  height: 7.08px;
  min-width: 159.37px;
  position: relative;
}

