.button-primary-with-icon-3 {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 42px;
  justify-content: center;
  margin-top: 44px;
  padding: 16px;
  position: relative;
  width: 178px;
}

.frame-1-2 {
  align-items: center;
  display: flex;
  gap: 9px;
  justify-content: center;
  margin-bottom: -9.00px;
  margin-left: -8.50px;
  margin-right: -8.50px;
  margin-top: -9.00px;
  position: relative;
  width: fit-content;
}

.button-name-2 {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.icon-11 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.icon-outlinearrow-right {
  height: 18px;
  min-width: 18px;
  position: relative;
}

.button-primary-with-icon-3.button-primary-with-icon-4 {
  margin-top: unset;
}

