.master {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 5.9px;
  position: relative;
}

.list-item-compact-ar {
  align-items: flex-end;
  align-self: stretch;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.content-frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  position: relative;
}

.icon-frame {
  align-items: center;
  display: flex;
  gap: 5.9px;
  justify-content: center;
  padding: 0px 5.9px 0px 0px;
  position: relative;
  width: fit-content;
}

.icon-8 {
  align-items: center;
  display: flex;
  gap: 5.9px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.text-frame {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 7.08px 0px;
  position: relative;
}

.main-text {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 5.9px;
  position: relative;
}

.content-leading {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

