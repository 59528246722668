.text-14-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 126px;
  width: 300px;
}

.unlimited-diagnostics {
  letter-spacing: 0;
  line-height: 34px;
  white-space: nowrap;
}

.we-provide-unlimited {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 72px;
  width: 296px;
}

.text-14-1.text-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 102px;
  width: 300px;
}

.text-14-1.text-15 .we-provide-unlimited {
  min-height: 48px;
}

