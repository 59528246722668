.x1-6 {
  align-items: center;
  display: flex;
  gap: 188px;
  height: 32px;
  min-width: 568px;
}

.step-1-diagnostic-assessment {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 354px;
  white-space: nowrap;
}

.icon-outlinecheveron-down-2 {
  height: 24px;
  margin-top: 0.79px;
  width: 24px;
}

.x1-6.x4-1 {
  gap: 201px;
  margin-top: 24px;
}

.x1-6.x4-1 .step-1-diagnostic-assessment {
  min-width: 341px;
}

.x1-6.x2-5 {
  gap: 138px;
  margin-top: 24px;
}

.x1-6.x2-5 .step-1-diagnostic-assessment {
  min-width: 404px;
}

.x1-6.x3-3 {
  gap: 99px;
  margin-top: 24px;
}

.x1-6.x3-3 .step-1-diagnostic-assessment {
  min-width: 443px;
}

