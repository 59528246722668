.elements-legend-text {
  align-items: flex-start;
  border-radius: 6.05px;
  display: flex;
  flex-direction: column;
  gap: 0.6px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.label {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: 7.2px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10.0px;
  margin-top: -0.60px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

