.button-primary-with-icon {
  align-items: center;
  border: 2px solid;
  border-color: var(--governor-bay);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 56px;
  justify-content: center;
  padding: 16px 20px;
  position: relative;
  width: fit-content;
}

.frame-1 {
  align-items: center;
  display: flex;
  gap: 9px;
  justify-content: center;
  margin-bottom: -0.14px;
  margin-top: -0.14px;
  position: relative;
  width: fit-content;
}

.button-name {
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

