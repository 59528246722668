.elements-data-y {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 85px;
  justify-content: space-between;
  position: relative;
  width: 35px;
}

