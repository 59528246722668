@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Inter:400,700,500,600|DM+Sans:700,500|Archivo:700|Outfit:700";
@import "https://px.animaapp.com/6426225dd29c0f9a138938c2.6426225e30b8b889772302ae.5sH4MxK.rcp.png";
@font-face {
  font-family: Cabinet Grotesk-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("CabinetGrotesk-Bold.81944140.otf") format("opentype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

* {
  box-sizing: border-box;
}

.x01-pos-landing-01 {
  background-color: var(--white);
  height: 8459px;
  width: 1600px;
  border: 1px;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
}

.overlap-group22 {
  height: 1671px;
  width: 1600px;
  position: relative;
}

.brand-logo {
  background-color: var(--white);
  min-height: 305px;
  width: 1600px;
  flex-direction: column;
  align-items: flex-end;
  gap: 80px;
  padding: 0 150.3px;
  display: flex;
  position: absolute;
  top: 1366px;
  left: 0;
}

.content-2 {
  min-height: 144px;
  width: 1297px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  margin-top: 80px;
  display: flex;
}

.trusted-by-these-organizations-and-more {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  letter-spacing: -.5px;
  min-height: 45px;
  min-width: 552px;
  text-align: center;
  white-space: nowrap;
  margin-right: 2.69px;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
}

.row-1 {
  height: 39px;
  width: 1295px;
  margin-right: 2px;
}

.line-261 {
  height: 1px;
  object-fit: cover;
  width: 1296px;
  align-self: center;
}

.hero-section {
  background-color: var(--bridal-heath);
  min-height: 1240px;
  width: 1600px;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 93px;
  padding: 30.4px 99px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  min-width: 1344px;
  align-items: center;
  display: flex;
}

.group {
  height: 24px;
  width: 46px;
  margin-top: .84px;
}

.sona-learn {
  color: #0000;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  min-height: 30px;
  width: 147px;
  margin-top: 12.08px;
  margin-left: 27px;
  font-weight: 700;
  line-height: 24px;
}

.span0 {
  color: var(--governor-bay);
}

.span1 {
  color: var(--magic-potion);
}

.buttons {
  height: 56px;
  min-width: 234px;
  align-items: flex-end;
  gap: 34px;
  margin-left: 890px;
  display: flex;
  position: relative;
}

.login {
  letter-spacing: 0;
  min-height: 38px;
  width: 43px;
  margin-bottom: 3.84px;
  line-height: 30px;
}

.text-content {
  min-height: 397px;
  width: 1256px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.text-container {
  height: 292px;
  width: 1260px;
  margin-left: 4px;
  position: relative;
}

.text {
  min-height: 292px;
  width: 1260px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.address {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  letter-spacing: 0;
  min-height: 176px;
  text-align: center;
  width: 1256px;
  margin-right: 4px;
  font-size: 80px;
  font-weight: 700;
  line-height: 88px;
}

.we-provide-every-stu {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  letter-spacing: -.5px;
  min-height: 96px;
  opacity: .8;
  text-align: center;
  width: 904px;
  margin-right: 4px;
  font-weight: 400;
  line-height: 32px;
}

.text-1 {
  height: 90px;
  width: 100px;
  position: absolute;
  top: 13px;
  left: 360px;
}

.screen-shot-2023-03-30-at-623-2 {
  height: 750px;
  width: 1133px;
  position: absolute;
  top: 624px;
  left: 205px;
}

.overlap-group24 {
  height: 1342px;
  width: 1600px;
  position: relative;
}

.content-section {
  background-color: var(--bridal-heath);
  min-height: 1342px;
  width: 1600px;
  flex-direction: column;
  align-items: flex-end;
  gap: 136px;
  padding: 50.3px 120px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group1-3 {
  height: 526px;
  width: 1320px;
  margin-right: 8px;
  position: relative;
}

.vector {
  height: 393px;
  width: 419px;
  position: absolute;
  top: 0;
  left: 666px;
}

.overlap-group-4 {
  height: 472px;
  width: 574px;
  position: absolute;
  top: 54px;
  left: 746px;
}

.bg {
  background-color: var(--magic-potion);
  height: 462px;
  width: 564px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.dashboard {
  background-color: var(--desert-storm);
  border: 5px solid;
  border-color: var(--magic-potion);
  height: 462px;
  width: 564px;
  border-radius: 10.89px;
  align-items: flex-start;
  padding: .1px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.header-1 {
  background-color: var(--magic-potion);
  height: 38px;
  min-width: 564px;
  align-items: flex-start;
  gap: 8px;
  padding: 11.3px 37.6px;
  display: flex;
}

.ellipse {
  background-color: var(--governor-bay);
  height: 14px;
  width: 14px;
  border: 1.4px solid;
  border-radius: 6.99px;
  position: relative;
}

.text-content-1 {
  min-height: 450px;
  width: 720px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 76px;
  left: 0;
}

.text-2 {
  min-height: 130px;
  width: 720px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.x1 {
  min-width: 634px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.icon {
  height: 30px;
  width: 28px;
  align-self: center;
}

.save-time-with-our-s, .get-a-curated-dynami, .get-automatic-detail, .we-precisely-identif, .with-a-target-score, .we-cater-all-our-ins {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 30px;
}

.x2 {
  min-width: 693px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.icon-1 {
  height: 30px;
  width: 28px;
  margin-top: 0;
}

.x3 {
  min-width: 722px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.content-2-1 {
  min-width: 1328px;
  align-items: flex-start;
  gap: 191px;
  display: flex;
}

.overlap-group2 {
  height: 510px;
  width: 469px;
  position: relative;
}

.vector-1 {
  height: 426px;
  width: 468px;
  position: absolute;
  top: 0;
  left: 0;
}

.cards {
  min-height: 450px;
  width: 430px;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  display: flex;
  position: absolute;
  top: 60px;
  left: 39px;
}

.flex-row {
  height: 264px;
  min-width: 430px;
  align-items: flex-start;
  gap: 36px;
  display: flex;
}

.overlap-group-5 {
  height: 259px;
  width: 226px;
  position: relative;
}

.bg-1 {
  background-color: var(--magic-potion);
  height: 254px;
  width: 221px;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.frame-6355990 {
  background-color: var(--desert-storm);
  border: 3px solid;
  border-color: var(--magic-potion);
  height: 254px;
  width: 221px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.screen-shot-2023-03-31-at-430-1 {
  height: 254px;
  object-fit: cover;
  width: 221px;
}

.card {
  background-color: var(--magic-potion);
  height: 193px;
  width: 168px;
  border-radius: 10px;
  align-self: flex-end;
}

.overlap-group1-4 {
  height: 156px;
  width: 226px;
  margin-right: 41.88px;
  position: relative;
}

.bg-2 {
  background-color: var(--magic-potion);
  height: 151px;
  width: 221px;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.frame-6355991 {
  background-color: var(--white);
  border: 3px solid;
  border-color: var(--magic-potion);
  height: 151px;
  width: 221px;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.flex-row-1 {
  height: 85px;
  min-width: 198px;
  align-self: center;
  align-items: center;
  gap: 3px;
  margin-top: 3px;
  margin-right: 2.36px;
  display: flex;
  position: relative;
}

.text-content-2 {
  min-height: 450px;
  width: 668px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.text-3 {
  min-height: 130px;
  width: 668px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.x1-1 {
  min-width: 611px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.x2-1 {
  min-width: 646px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.x3-1 {
  min-width: 670px;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.summary_-final-2 {
  height: 184px;
  width: 161px;
  position: absolute;
  top: 847px;
  left: 456px;
}

.summary_-final-1 {
  height: 418px;
  width: 552px;
  position: absolute;
  top: 142px;
  left: 904px;
}

.service-section {
  min-width: 1600px;
  align-items: flex-start;
  display: flex;
}

.overlap-group20 {
  height: 1694px;
  width: 1600px;
  position: relative;
}

.bg-3 {
  background-color: var(--white);
  height: 822px;
  width: 1600px;
  position: absolute;
  top: 0;
  left: 0;
}

.content-3 {
  min-height: 1574px;
  width: 1306px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  display: flex;
  position: absolute;
  top: 120px;
  left: 152px;
}

.our-core-features-an {
  letter-spacing: -1px;
  min-height: 108px;
  text-align: center;
  width: 636px;
  margin-right: 10px;
  line-height: 54px;
}

.overlap-group19 {
  height: 1386px;
  width: 1302px;
  margin-right: 4px;
  position: relative;
}

.additional-add-ons {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  letter-spacing: -1px;
  text-align: center;
  white-space: nowrap;
  width: 636px;
  font-size: 40px;
  font-weight: 700;
  line-height: 54px;
  position: absolute;
  top: 683px;
  left: 327px;
}

.services {
  height: 1386px;
  min-width: 1302px;
  align-items: flex-start;
  gap: 18px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group-container {
  min-height: 1386px;
  width: 422px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.overlap-group {
  height: 185px;
  width: 416px;
  margin-right: 6px;
  position: relative;
}

.overlap-group-1 {
  height: 185px;
  width: 416px;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-4, .bg-6, .bg-8, .bg-10, .bg-12, .bg-14, .bg-16, .bg-18, .bg-20, .bg-22, .bg-24, .bg-26, .bg-28, .bg-30, .bg-32, .bg-34, .bg-36, .bg-38 {
  background-color: var(--magic-potion);
  height: 181px;
  width: 405px;
  border-radius: 10px;
  position: absolute;
  top: 4px;
  left: 11px;
}

.bg-5, .bg-7, .bg-9, .bg-17, .bg-19, .bg-21, .bg-29, .bg-31, .bg-33 {
  background-color: var(--bridal-heath);
  border: 3px solid;
  border-color: var(--magic-potion);
  height: 180px;
  width: 411px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  height: 126px;
  min-width: 356px;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 30px;
  left: 30px;
}

.icon-2 {
  background-color: var(--magic-potion);
  min-width: 40px;
  align-items: flex-start;
  display: flex;
}

.overlap-group-2 {
  height: 40px;
  width: 40px;
  position: relative;
}

.shape {
  height: 40px;
  width: 21px;
  position: absolute;
  top: 0;
  left: 0;
}

.shape-1 {
  height: 40px;
  width: 21px;
  position: absolute;
  top: 0;
  left: 19px;
}

.shape-2 {
  height: 21px;
  width: 40px;
  position: absolute;
  top: 19px;
  left: 0;
}

.overlap-group-3 {
  height: 185px;
  width: 416px;
  margin-top: 24px;
  margin-right: 6px;
  position: relative;
}

.icon-3 {
  background-color: var(--sunglow);
  height: 40px;
  width: 40px;
  background-image: url("shape-10.dcee4525.svg");
  background-size: 100% 100%;
}

.icon-4 {
  background-color: var(--magic-potion);
  height: 40px;
  min-width: 40px;
  align-items: flex-start;
  display: flex;
}

.shape-3 {
  background-color: var(--sea-serpent);
  height: 20px;
  width: 20px;
}

.shape-4 {
  background-color: var(--sunglow);
  height: 20px;
  width: 20px;
  align-self: flex-end;
}

.overlap-group1 {
  height: 185px;
  width: 416px;
  margin-top: 180px;
  margin-left: 2px;
  position: relative;
}

.bg-11, .bg-13, .bg-15, .bg-23, .bg-25, .bg-27, .bg-35, .bg-37, .bg-39 {
  background-color: var(--white);
  border: 3px solid;
  border-color: var(--magic-potion);
  height: 180px;
  width: 411px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group1-1 {
  height: 185px;
  width: 416px;
  margin-top: 24px;
  margin-left: 2px;
  position: relative;
}

.overlap-group1-2 {
  height: 185px;
  width: 416px;
  margin-top: 24px;
  margin-left: 6px;
  position: relative;
}

.content-4 {
  height: 102px;
  min-width: 356px;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 30px;
  left: 30px;
}

.icon-5 {
  background-color: var(--princess-perfume);
  min-width: 40px;
  align-items: flex-start;
  display: flex;
  transform: rotate(180deg);
}

.shape-5 {
  height: 40px;
  width: 40px;
  transform: rotate(-180deg);
}

.content-1 {
  height: 126px;
  min-width: 356px;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 30px;
  left: 29px;
}

.content-5 {
  height: 126px;
  min-width: 362px;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: absolute;
  top: 30px;
  left: 29px;
}

.text-4 {
  min-height: 126px;
  width: 306px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.cancellation-reschedule {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 34px;
}

.tutors-or-students-c {
  letter-spacing: 0;
  min-height: 72px;
  width: 296px;
  line-height: 24px;
}

.icon-7 {
  background-color: var(--magic-potion);
  min-width: 40px;
  align-items: flex-end;
  padding: 0;
  display: flex;
}

.overlap-group-6 {
  height: 40px;
  width: 40px;
  margin-bottom: 0;
  position: relative;
}

.shape-6 {
  height: 40px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.shape-7 {
  height: 26px;
  width: 21px;
  position: absolute;
  top: 14px;
  left: 19px;
}

.overlap-group4 {
  height: 185px;
  width: 416px;
  margin-top: 24px;
  margin-right: 2px;
  position: relative;
}

.ent-container {
  height: 701px;
  width: 1600px;
  margin-top: 87px;
  margin-right: -5px;
  position: relative;
}

.content-2-2 {
  height: 701px;
  min-width: 1600px;
  background-color: #f8fafc;
  justify-content: flex-end;
  align-items: center;
  gap: 31px;
  padding: 41px 173px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.content-left {
  min-height: 523px;
  width: 645px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.deliver-a-learning-e {
  color: var(--eerie-black);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  min-height: 144px;
  width: 545px;
  margin-left: 5px;
  font-weight: 700;
  line-height: 48px;
}

.text-5 {
  min-height: 79px;
  width: 585px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 24px;
  margin-left: 5px;
  display: flex;
}

.x1-2 {
  height: 32px;
  min-width: 418px;
  align-items: center;
  gap: 12px;
  display: flex;
}

.icon-6 {
  height: 20px;
  width: 20px;
}

.unlimited-diagnostic {
  letter-spacing: -.5px;
  min-width: 384px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.x2-2 {
  height: 32px;
  min-width: 587px;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.x50000-practice-que {
  letter-spacing: -.5px;
  min-width: 553px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.text-6 {
  min-height: 173px;
  width: 642px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 17px;
  margin-left: 3px;
  display: flex;
}

.x1-3 {
  height: 32px;
  min-width: 568px;
  align-items: center;
  gap: 12px;
  margin-left: 2px;
  display: flex;
}

.daily-weekly-month {
  letter-spacing: -.5px;
  min-width: 534px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.x2-3 {
  height: 32px;
  min-width: 600px;
  align-items: center;
  gap: 12px;
  margin-left: 2px;
  display: flex;
  position: relative;
}

.personalized-monitor {
  letter-spacing: -.5px;
  min-width: 566px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.x3-2 {
  height: 32px;
  min-width: 644px;
  align-items: center;
  gap: 12px;
  display: flex;
}

.ai-academic-advisor {
  letter-spacing: -.5px;
  min-width: 610px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.x4 {
  height: 32px;
  min-width: 346px;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.guaranteed-score-increase-priceless {
  letter-spacing: -.5px;
  min-width: 312px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.overlap-group-7 {
  height: 419px;
  width: 494px;
  margin-bottom: 40px;
  position: relative;
}

.bg-40 {
  background-color: var(--white);
  height: 419px;
  width: 424px;
  border-radius: 6.63px;
  position: absolute;
  top: 0;
  left: 0;
}

.graph {
  background-color: var(--vulcan);
  height: 53px;
  width: 494px;
  position: absolute;
  top: 0;
  left: 0;
}

.blue-and-yellow-simp {
  height: 375px;
  width: 494px;
  position: absolute;
  top: 174px;
  left: 933px;
}

.ent-container-1 {
  height: 659px;
  width: 1600px;
  margin-top: 101px;
  margin-right: -5px;
  position: relative;
}

.content-3-1 {
  background-color: var(--white);
  height: 659px;
  min-width: 1600px;
  justify-content: flex-end;
  align-items: center;
  gap: 178px;
  padding: 0 39px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group1-5 {
  height: 419px;
  width: 529px;
  position: relative;
}

.overlap-group-8 {
  height: 419px;
  width: 529px;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-41 {
  background-color: var(--white);
  height: 419px;
  width: 424px;
  border-radius: 6.63px;
  position: absolute;
  top: 0;
  left: 52px;
}

.graph-1 {
  background-color: var(--vulcan);
  height: 53px;
  width: 529px;
  position: absolute;
  top: 0;
  left: 0;
}

.content-left-1 {
  min-height: 385px;
  width: 644px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  display: flex;
  position: relative;
}

.text-7 {
  min-height: 212px;
  width: 574px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-left: 5px;
  display: flex;
}

.handle-5000-the-amo {
  color: var(--eerie-black);
  font-family: var(--font-family-outfit);
  font-size: var(--font-size-xxxl);
  letter-spacing: 0;
  min-height: 96px;
  width: 568px;
  font-weight: 700;
  line-height: 48px;
}

.our-system-does-all {
  letter-spacing: 0;
  min-height: 96px;
  opacity: .7;
  width: 570px;
  line-height: 24px;
}

.text-8 {
  min-height: 79px;
  width: 639px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  gap: 15px;
  display: flex;
}

.x1-4 {
  height: 32px;
  min-width: 496px;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.giving-you-a-persona {
  letter-spacing: -.5px;
  min-width: 462px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.x2-4 {
  height: 32px;
  min-width: 641px;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.focus-on-hyper-scali {
  letter-spacing: -.5px;
  min-width: 607px;
  opacity: .7;
  white-space: nowrap;
  line-height: 32px;
}

.blue-and-yellow-simp-1 {
  height: 356px;
  object-fit: cover;
  width: 530px;
  position: absolute;
  top: 173px;
  left: 209px;
}

.content-section-2 {
  background-color: var(--bridal-heath);
  height: 852px;
  min-width: 1600px;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 57.6px 57px;
  display: flex;
}

.overlap-group1-6 {
  height: 734px;
  width: 1391px;
  position: relative;
}

.content-6 {
  height: 734px;
  min-width: 1391px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.text-content-3 {
  min-height: 674px;
  width: 636px;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  gap: 50px;
  display: flex;
}

.text-9 {
  min-height: 186px;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  display: flex;
}

.foundational-5-step {
  letter-spacing: -1px;
  min-height: 108px;
  width: 636px;
  line-height: 54px;
}

.our-5-step-method-en {
  letter-spacing: 0;
  min-height: 60px;
  opacity: .8;
  width: 557px;
  line-height: 30px;
}

.text-10 {
  min-height: 438px;
  width: 577px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.line-24 {
  height: 1px;
  object-fit: cover;
  width: 575px;
  margin-top: 25px;
}

.line-245 {
  height: 1px;
  object-fit: cover;
  width: 575px;
  margin-top: 23px;
}

.flex-row-2 {
  height: 32px;
  min-width: 566px;
  align-items: center;
  gap: 30px;
  margin-top: 24px;
  display: flex;
}

.step-5-detailed-re {
  letter-spacing: -.5px;
  min-width: 512px;
  white-space: nowrap;
  line-height: 32px;
}

.icon-outlinecheveron-down {
  height: 24px;
  width: 24px;
  margin-top: .79px;
}

.line-248 {
  height: 1px;
  object-fit: cover;
  width: 575px;
  margin-top: 27px;
}

.icon-outlinecheveron-down-1 {
  height: 24px;
  width: 24px;
  align-self: flex-end;
  margin-top: 28px;
  margin-right: 11.1px;
}

.overlap-group-9 {
  height: 615px;
  width: 755px;
  margin-top: 23.12px;
  position: relative;
}

.vector-2 {
  height: 393px;
  width: 419px;
  position: absolute;
  top: 0;
  left: 0;
}

.card-1 {
  background-color: var(--magic-potion);
  height: 526px;
  width: 667px;
  border-radius: 10.32px;
  position: absolute;
  top: 89px;
  left: 88px;
}

.blue-and-yellow-simp-2 {
  height: 498px;
  object-fit: cover;
  width: 667px;
  position: absolute;
  top: 126px;
  left: 724px;
}

.overlap-group23 {
  height: 816px;
  width: 1600px;
  position: relative;
}

.testimonial-section {
  background-color: var(--white);
  min-height: 816px;
  width: 1600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  padding: 120px 152px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.what-our-past-parent {
  letter-spacing: -1px;
  min-height: 108px;
  text-align: center;
  width: 572px;
  align-self: center;
  line-height: 54px;
}

.x1-5 {
  background-color: var(--bridal-heath);
  min-height: 388px;
  width: 416px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 30px 0;
  display: flex;
  position: absolute;
  top: 308px;
  left: 592px;
}

.sona-allowed-us-to {
  letter-spacing: 0;
  min-height: 224px;
  width: 336px;
  margin-right: 20px;
  line-height: 28px;
}

.img-text {
  min-height: 100px;
  width: 356px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  display: flex;
}

.line-242 {
  height: 2px;
  width: 356px;
  margin-top: -2px;
}

.text-11 {
  min-width: 356px;
  align-items: center;
  gap: 15px;
  display: flex;
}

.image {
  height: 70px;
  width: 70px;
}

.text-12 {
  height: 58px;
  min-width: 275px;
  align-items: flex-end;
  gap: 16px;
  margin-top: 4px;
  display: flex;
}

.flex-col {
  min-height: 58px;
  width: 147px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  display: flex;
}

.flex-col-item {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 26px;
}

.favourite-31 {
  height: 18px;
  width: 108px;
  margin-bottom: 4px;
}

.footer-section {
  height: 543px;
  min-width: 1600px;
  align-items: flex-start;
  display: flex;
}

.overlap-group-10 {
  background-color: var(--bridal-heath);
  height: 536px;
  width: 1600px;
  background-size: 100% 100%;
  position: relative;
}

.content-7 {
  min-height: 399px;
  width: 1296px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 57px;
  left: 152px;
}

.cta-text {
  min-height: 167px;
  width: 626px;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.get-a-customized-ver {
  letter-spacing: -1px;
  min-height: 97px;
  text-align: center;
  width: 626px;
  line-height: 54px;
}

.we-pride-ourselves-i {
  letter-spacing: 0;
  min-height: 54px;
  opacity: .8;
  text-align: center;
  width: 558px;
  line-height: 30px;
}

.line-261-1 {
  height: 1px;
  object-fit: cover;
  width: 1296px;
  margin-top: 169px;
}

.copyright-2022-al {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  letter-spacing: 0;
  min-height: 18px;
  text-align: right;
  white-space: nowrap;
  width: 500px;
  margin-top: 44px;
  margin-bottom: -2px;
  margin-left: 1px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.x1-7 {
  background-color: var(--bridal-heath);
  min-height: 388px;
  width: 416px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 30px 0;
  display: flex;
}

.this-software-is-tr {
  letter-spacing: 0;
  min-height: 196px;
  width: 336px;
  margin-right: 20px;
  line-height: 28px;
}

.img-text-1 {
  min-height: 100px;
  width: 356px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  display: flex;
}

.line-242-1 {
  height: 2px;
  width: 356px;
  margin-top: -2px;
}

.text-16 {
  min-width: 266px;
  align-items: center;
  gap: 15px;
  display: flex;
}

.image-1 {
  height: 70px;
  width: 70px;
}

.text-17 {
  min-height: 58px;
  width: 185px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 4px;
  display: flex;
}

.name {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 26px;
}

.flex-row-3 {
  height: 26px;
  min-width: 181px;
  align-items: center;
  gap: 27px;
  display: flex;
}

.tutor {
  letter-spacing: 0;
  min-width: 46px;
  white-space: nowrap;
  line-height: 26px;
}

.favourite-31-1 {
  height: 18px;
  width: 108px;
}

.x1-7.x2-6 {
  gap: 4px;
  position: absolute;
  top: 308px;
  left: 1032px;
}

.x1-7.x2-6 .this-software-is-tr {
  min-height: 224px;
}

.x1-7.x2-6 .flex-row-3 {
  gap: 16px;
}

.x1-7.x2-6 .tutor {
  min-width: 57px;
}

.x1-6 {
  height: 32px;
  min-width: 568px;
  align-items: center;
  gap: 188px;
  display: flex;
}

.step-1-diagnostic-assessment {
  letter-spacing: -.5px;
  min-width: 354px;
  white-space: nowrap;
  line-height: 32px;
}

.icon-outlinecheveron-down-2 {
  height: 24px;
  width: 24px;
  margin-top: .79px;
}

.x1-6.x4-1 {
  gap: 201px;
  margin-top: 24px;
}

.x1-6.x4-1 .step-1-diagnostic-assessment {
  min-width: 341px;
}

.x1-6.x2-5 {
  gap: 138px;
  margin-top: 24px;
}

.x1-6.x2-5 .step-1-diagnostic-assessment {
  min-width: 404px;
}

.x1-6.x3-3 {
  gap: 99px;
  margin-top: 24px;
}

.x1-6.x3-3 .step-1-diagnostic-assessment {
  min-width: 443px;
}

.total-invoice {
  background-color: var(--white);
  height: 97px;
  width: 216px;
  border-radius: 12.93px;
  position: absolute;
  top: 78px;
  left: 303px;
  box-shadow: 0 2.16px 32.33px #00000014;
}

.button-primary-with-icon-3 {
  height: 42px;
  width: 178px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 44px;
  padding: 16px;
  display: flex;
  position: relative;
}

.frame-1-2 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin: -9px -8.5px;
  display: flex;
  position: relative;
}

.button-name-2 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 28px;
  position: relative;
}

.icon-11 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.icon-outlinearrow-right {
  height: 18px;
  min-width: 18px;
  position: relative;
}

.button-primary-with-icon-3.button-primary-with-icon-4 {
  margin-top: unset;
}

.icon-10 {
  background-color: var(--blue);
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  align-items: flex-end;
  padding: 5.8px 4px;
  display: flex;
}

.vector-3 {
  height: 8px;
  width: 11px;
}

.icon-9 {
  background-color: var(--magic-potion);
  min-width: 40px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.shape-8 {
  height: 40px;
  width: 20px;
  margin-bottom: 0;
}

.flex-col-1 {
  min-height: 40px;
  width: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  display: flex;
}

.shape-9 {
  background-color: var(--sea-serpent);
  height: 20px;
  width: 20px;
}

.shape-10 {
  background-color: var(--sunglow);
  height: 20px;
  width: 20px;
}

.text-14-1 {
  min-height: 126px;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.unlimited-diagnostics {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 34px;
}

.we-provide-unlimited {
  letter-spacing: 0;
  min-height: 72px;
  width: 296px;
  line-height: 24px;
}

.text-14-1.text-15 {
  min-height: 102px;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.text-14-1.text-15 .we-provide-unlimited {
  min-height: 48px;
}

.charts-custom {
  height: 83px;
  width: 161px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1px;
  display: flex;
  position: relative;
}

.x-line {
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.x1-section {
  width: 100.34px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle {
  height: 7.08px;
  min-width: 100.34px;
  background: linear-gradient(#edde5d 0%, #f09819 100%);
  align-self: stretch;
  position: relative;
}

.x3-section {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle-1 {
  background-color: var(--black-haze);
  height: 7.08px;
  min-width: 60.21px;
  border-radius: 0 59.03px 59.03px 0;
  align-self: stretch;
  position: relative;
}

.x1-section-1 {
  width: 33.05px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle-2 {
  height: 7.08px;
  min-width: 33.05px;
  background: linear-gradient(#edde5d 0%, #f09819 100%);
  align-self: stretch;
  position: relative;
}

.rectangle-3 {
  background-color: var(--black-haze);
  height: 7.08px;
  min-width: 127.49px;
  border-radius: 0 59.03px 59.03px 0;
  align-self: stretch;
  position: relative;
}

.x-line-1 {
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.x1-section-2 {
  width: 17.71px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle-4 {
  height: 7.08px;
  min-width: 17.71px;
  background: linear-gradient(#edde5d 0%, #f09819 100%);
  align-self: stretch;
  position: relative;
}

.rectangle-5 {
  background-color: var(--black-haze);
  height: 7.08px;
  min-width: 142.84px;
  border-radius: 0 59.03px 59.03px 0;
  align-self: stretch;
  position: relative;
}

.x1-section-3 {
  width: 9.44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle-6 {
  height: 7.08px;
  min-width: 9.44px;
  background: linear-gradient(#edde5d 0%, #f09819 100%);
  align-self: stretch;
  position: relative;
}

.rectangle-7 {
  background-color: var(--black-haze);
  height: 7.08px;
  min-width: 151.1px;
  border-radius: 0 59.03px 59.03px 0;
  align-self: stretch;
  position: relative;
}

.x1-section-4 {
  width: 1.18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.rectangle-8 {
  height: 7.08px;
  min-width: 1.18px;
  background: linear-gradient(#edde5d 0%, #f09819 100%);
  align-self: stretch;
  position: relative;
}

.rectangle-9 {
  background-color: var(--black-haze);
  height: 7.08px;
  min-width: 159.37px;
  border-radius: 0 59.03px 59.03px 0;
  align-self: stretch;
  position: relative;
}

.elements-data-y {
  height: 85px;
  width: 35px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.elements-data-lines-y-1 {
  align-self: stretch;
  align-items: center;
  padding: .6px 0 0;
  display: flex;
  position: relative;
}

.elements-legend-text-1 {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6.05px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .6px;
  display: flex;
  position: relative;
}

.label-1 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.6px;
  line-height: 10px;
  position: relative;
}

.elements-data-lines-y {
  align-self: stretch;
  align-items: center;
  padding: .6px 0 0;
  display: flex;
  position: relative;
}

.elements-legend-text {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6.05px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .6px;
  display: flex;
  position: relative;
}

.label {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.6px;
  font-size: 7.2px;
  font-weight: 700;
  line-height: 10px;
  position: relative;
}

.basic {
  width: 165px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.master {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.list-item-compact-ar {
  background-color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  display: flex;
  position: relative;
}

.content-frame {
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.icon-frame {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 5.9px;
  padding: 0 5.9px 0 0;
  display: flex;
  position: relative;
}

.icon-8 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.text-frame {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 7.08px 0;
  display: flex;
  position: relative;
}

.main-text {
  align-self: stretch;
  align-items: center;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.content-leading {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.elements-text-box-table-row {
  width: 94.44px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: -25.06px;
  display: flex;
  position: relative;
}

.title-1 {
  color: var(--magic-potion);
  font-family: var(--font-family-dm_sans);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -.59px;
  font-size: 7.7px;
  font-weight: 500;
  line-height: 10.7px;
  position: relative;
}

.artwork {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  display: flex;
  position: relative;
}

.master-1 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.container-1 {
  height: 14.17px;
  width: 14.17px;
  align-items: flex-start;
  gap: 5.9px;
  display: flex;
  position: relative;
}

.solid-star {
  height: 14.17px;
  min-width: 14.17px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.master-1.master-5 .container-1 {
  height: unset;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.master-1.master-5 .solid-star {
  align-self: unset;
  flex: unset;
}

.elements-spacer {
  height: 9.44px;
  min-width: 9.44px;
  position: relative;
}

.elements-spacer.elements-spacer-1 {
  height: 25.17px;
  align-self: stretch;
}

.navigation-bars-title-24-no-no {
  background-color: var(--white);
  height: 27px;
  width: 201px;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 2px;
  padding: 5.9px 0 0;
  display: flex;
  position: relative;
}

.container {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  padding: 0 9.44px;
  display: flex;
  position: relative;
}

.elements-text-box-nav-bar {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.title {
  color: var(--magic-potion);
  font-family: var(--font-family-dm_sans);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  margin-top: -.59px;
  font-size: 26px;
  font-weight: 700;
  line-height: 36.4px;
  position: relative;
}

.text-13-1 {
  min-height: 300px;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  display: flex;
}

.save-tutors-40-hour {
  letter-spacing: -1px;
  min-height: 162px;
  width: 636px;
  line-height: 54px;
}

.our-ai-advisor-syste {
  letter-spacing: 0;
  min-height: 120px;
  opacity: .8;
  width: 636px;
  line-height: 30px;
}

.text-13-1.text-13 .our-ai-advisor-syste {
  width: 603px;
}

.button-primary-with-icon-1 {
  background-color: var(--governor-bay);
  height: 65px;
  width: 250px;
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 58px;
  padding: 16px 20px;
  display: flex;
  position: relative;
}

.frame-1-1 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 9px;
  display: flex;
  position: relative;
}

.button-name-1 {
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
}

.button-primary-with-icon-1.button-primary-with-icon-2 {
  height: 58px;
  margin-right: unset;
  position: absolute;
  top: 260px;
  left: 675px;
}

.button-primary-with-icon {
  border: 2px solid;
  border-color: var(--governor-bay);
  height: 56px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 20px;
  display: flex;
  position: relative;
}

.frame-1 {
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin-top: -.14px;
  margin-bottom: -.14px;
  display: flex;
  position: relative;
}

.button-name {
  letter-spacing: 0;
  text-align: right;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  line-height: 24px;
  position: relative;
}



:root {
  --black: #000;
  --black-haze: #f4f6f9;
  --blue: #2b59ff;
  --bridal-heath: #fff9f3;
  --desert-storm: #f8f8f8;
  --eerie-black: #18181b;
  --governor-bay: #3a30ba;
  --magic-potion: #080a47;
  --princess-perfume: #f480d4;
  --sea-serpent: #45c9c1;
  --storm-dust: #616161;
  --sunglow: #ffc947;
  --vulcan: #12141d;
  --white: #fff;
  --font-size-l: 20px;
  --font-size-m: 18px;
  --font-size-s: 16px;
  --font-size-xl: 24px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 42px;
  --font-size-xxxxl: 48px;
  --font-family-archivo: "Archivo", Helvetica;
  --font-family-cabinet_grotesk-bold: "Cabinet Grotesk-Bold", Helvetica;
  --font-family-dm_sans: "DM Sans", Helvetica;
  --font-family-inter: "Inter", Helvetica;
  --font-family-outfit: "Outfit", Helvetica;
}

.cabinetgrotesk-bold-magic-potion-28px {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.inter-normal-magic-potion-16px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-vulcan-18px {
  color: var(--vulcan);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.cabinetgrotesk-bold-magic-potion-48px {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.inter-semi-bold-magic-potion-18px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.inter-semi-bold-vulcan-18px {
  color: var(--vulcan);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.inter-semi-bold-magic-potion-24px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.inter-normal-magic-potion-18px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.archivo-bold-magic-potion-7-2px {
  color: var(--magic-potion);
  font-family: var(--font-family-archivo);
  font-size: 7.2px;
  font-style: normal;
  font-weight: 700;
}

.inter-semi-bold-magic-potion-20px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
}

.inter-bold-magic-potion-18px {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.inter-medium-storm-dust-18px {
  color: var(--storm-dust);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.inter-semi-bold-governor-bay-16px {
  color: var(--governor-bay);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.inter-semi-bold-bridal-heath-16px {
  color: var(--bridal-heath);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.inter-bold-blue-16px {
  color: var(--blue);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

/*# sourceMappingURL=index.a8d6e16e.css.map */
