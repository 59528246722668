.x01-pos-landing-01 {
  align-items: flex-end;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 8459px;
  overflow: hidden;
  width: 1600px;
}

.overlap-group22 {
  height: 1671px;
  position: relative;
  width: 1600px;
}

.brand-logo {
  align-items: flex-end;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 80px;
  left: 0;
  min-height: 305px;
  padding: 0 150.3px;
  position: absolute;
  top: 1366px;
  width: 1600px;
}

.content-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 80px;
  min-height: 144px;
  width: 1297px;
}

.trusted-by-these-organizations-and-more {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.50px;
  line-height: 44.8px;
  margin-right: 2.69px;
  min-height: 45px;
  min-width: 552px;
  text-align: center;
  white-space: nowrap;
}

.row-1 {
  height: 39px;
  margin-right: 2.0px;
  width: 1295px;
}

.line-261 {
  align-self: center;
  height: 1px;
  object-fit: cover;
  width: 1296px;
}

.hero-section {
  align-items: flex-start;
  background-color: var(--bridal-heath);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 93px;
  left: 0;
  min-height: 1240px;
  padding: 30.4px 99px;
  position: absolute;
  top: 0;
  width: 1600px;
}

.header {
  align-items: center;
  display: flex;
  min-width: 1344px;
}

.group {
  height: 24px;
  margin-top: 0.84px;
  width: 46px;
}

.sona-learn {
  color: transparent;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 27px;
  margin-top: 12.08px;
  min-height: 30px;
  width: 147px;
}

.span0 {
  color: var(--governor-bay);
}

.span1 {
  color: var(--magic-potion);
}

.buttons {
  align-items: flex-end;
  display: flex;
  gap: 34px;
  height: 56px;
  margin-left: 890px;
  min-width: 234px;
  position: relative;
}

.login {
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 3.84px;
  min-height: 38px;
  width: 43px;
}

.text-content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 397px;
  position: relative;
  width: 1256px;
}

.text-container {
  height: 292px;
  margin-left: 4.0px;
  position: relative;
  width: 1260px;
}

.text {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  min-height: 292px;
  position: absolute;
  top: 0;
  width: 1260px;
}

.address {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 88px;
  margin-right: 4.0px;
  min-height: 176px;
  text-align: center;
  width: 1256px;
}

.we-provide-every-stu {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: -0.50px;
  line-height: 32px;
  margin-right: 4.0px;
  min-height: 96px;
  opacity: 0.8;
  text-align: center;
  width: 904px;
}

.text-1 {
  height: 90px;
  left: 360px;
  position: absolute;
  top: 13px;
  width: 100px;
}

.screen-shot-2023-03-30-at-623-2 {
  height: 750px;
  left: 205px;
  position: absolute;
  top: 624px;
  width: 1133px;
}

.overlap-group24 {
  height: 1342px;
  position: relative;
  width: 1600px;
}

.content-section {
  align-items: flex-end;
  background-color: var(--bridal-heath);
  display: flex;
  flex-direction: column;
  gap: 136px;
  left: 0;
  min-height: 1342px;
  padding: 50.3px 120px;
  position: absolute;
  top: 0;
  width: 1600px;
}

.overlap-group1-3 {
  height: 526px;
  margin-right: 8px;
  position: relative;
  width: 1320px;
}

.vector {
  height: 393px;
  left: 666px;
  position: absolute;
  top: 0;
  width: 419px;
}

.overlap-group-4 {
  height: 472px;
  left: 746px;
  position: absolute;
  top: 54px;
  width: 574px;
}

.bg {
  background-color: var(--magic-potion);
  border-radius: 10px;
  height: 462px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 564px;
}

.dashboard {
  align-items: flex-start;
  background-color: var(--desert-storm);
  border: 5px solid;
  border-color: var(--magic-potion);
  border-radius: 10.89px;
  display: flex;
  height: 462px;
  left: 0;
  overflow: hidden;
  padding: 0.1px 0;
  position: absolute;
  top: 0;
  width: 564px;
}

.header-1 {
  align-items: flex-start;
  background-color: var(--magic-potion);
  display: flex;
  gap: 8px;
  height: 38px;
  min-width: 564px;
  padding: 11.3px 37.6px;
}

.ellipse {
  background-color: var(--governor-bay);
  border: 1.4px solid;
  border-radius: 6.99px;
  height: 14px;
  position: relative;
  width: 14px;
}

.text-content-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  min-height: 450px;
  position: absolute;
  top: 76px;
  width: 720px;
}

.text-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 130px;
  width: 720px;
}

.x1 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 634px;
}

.icon {
  align-self: center;
  height: 30px;
  width: 28px;
}

.save-time-with-our-s,
.get-a-curated-dynami,
.get-automatic-detail,
.we-precisely-identif,
.with-a-target-score,
.we-cater-all-our-ins {
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
}

.x2 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 693px;
}

.icon-1 {
  height: 30px;
  margin-top: 0;
  width: 28px;
}

.x3 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 722px;
}

.content-2-1 {
  align-items: flex-start;
  display: flex;
  gap: 191px;
  min-width: 1328px;
}

.overlap-group2 {
  height: 510px;
  position: relative;
  width: 469px;
}

.vector-1 {
  height: 426px;
  left: 0;
  position: absolute;
  top: 0;
  width: 468px;
}

.cards {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 39px;
  min-height: 450px;
  position: absolute;
  top: 60px;
  width: 430px;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  gap: 36px;
  height: 264px;
  min-width: 430px;
}

.overlap-group-5 {
  height: 259px;
  position: relative;
  width: 226px;
}

.bg-1 {
  background-color: var(--magic-potion);
  border-radius: 10px;
  height: 254px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 221px;
}

.frame-6355990 {
  align-items: flex-end;
  background-color: var(--desert-storm);
  border: 3px solid;
  border-color: var(--magic-potion);
  border-radius: 10px;
  display: flex;
  height: 254px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 221px;
}

.screen-shot-2023-03-31-at-430-1 {
  height: 254px;
  object-fit: cover;
  width: 221px;
}

.card {
  align-self: flex-end;
  background-color: var(--magic-potion);
  border-radius: 10px;
  height: 193px;
  width: 168px;
}

.overlap-group1-4 {
  height: 156px;
  margin-right: 41.88px;
  position: relative;
  width: 226px;
}

.bg-2 {
  background-color: var(--magic-potion);
  border-radius: 10px;
  height: 151px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 221px;
}

.frame-6355991 {
  align-items: flex-start;
  background-color: var(--white);
  border: 3px solid;
  border-color: var(--magic-potion);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 151px;
  left: 0;
  overflow: hidden;
  padding: 1px 0;
  position: absolute;
  top: 0;
  width: 221px;
}

.flex-row-1 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 3px;
  height: 85px;
  margin-right: 2.36px;
  margin-top: 3px;
  min-width: 198px;
  position: relative;
}

.text-content-2 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 450px;
  position: relative;
  width: 668px;
}

.text-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 130px;
  width: 668px;
}

.x1-1 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 611px;
}

.x2-1 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 646px;
}

.x3-1 {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  min-width: 670px;
}

.summary_-final-2 {
  height: 184px;
  left: 456px;
  position: absolute;
  top: 847px;
  width: 161px;
}

.summary_-final-1 {
  height: 418px;
  left: 904px;
  position: absolute;
  top: 142px;
  width: 552px;
}

.service-section {
  align-items: flex-start;
  display: flex;
  min-width: 1600px;
}

.overlap-group20 {
  height: 1694px;
  position: relative;
  width: 1600px;
}

.bg-3 {
  background-color: var(--white);
  height: 822px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1600px;
}

.content-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
  left: 152px;
  min-height: 1574px;
  position: absolute;
  top: 120px;
  width: 1306px;
}

.our-core-features-an {
  letter-spacing: -1.00px;
  line-height: 54px;
  margin-right: 10.0px;
  min-height: 108px;
  text-align: center;
  width: 636px;
}

.overlap-group19 {
  height: 1386px;
  margin-right: 4.0px;
  position: relative;
  width: 1302px;
}

.additional-add-ons {
  color: var(--magic-potion);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: 40px;
  font-weight: 700;
  left: 327px;
  letter-spacing: -1.00px;
  line-height: 54px;
  position: absolute;
  text-align: center;
  top: 683px;
  white-space: nowrap;
  width: 636px;
}

.services {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  height: 1386px;
  left: 0;
  min-width: 1302px;
  position: absolute;
  top: 0;
}

.overlap-group-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 1386px;
  width: 422px;
}

.overlap-group {
  height: 185px;
  margin-right: 6.0px;
  position: relative;
  width: 416px;
}

.overlap-group-1 {
  height: 185px;
  left: 0;
  position: absolute;
  top: 0;
  width: 416px;
}

.bg-4,
.bg-6,
.bg-8,
.bg-10,
.bg-12,
.bg-14,
.bg-16,
.bg-18,
.bg-20,
.bg-22,
.bg-24,
.bg-26,
.bg-28,
.bg-30,
.bg-32,
.bg-34,
.bg-36,
.bg-38 {
  background-color: var(--magic-potion);
  border-radius: 10px;
  height: 181px;
  left: 11px;
  position: absolute;
  top: 4px;
  width: 405px;
}

.bg-5,
.bg-7,
.bg-9,
.bg-17,
.bg-19,
.bg-21,
.bg-29,
.bg-31,
.bg-33 {
  background-color: var(--bridal-heath);
  border: 3px solid;
  border-color: var(--magic-potion);
  border-radius: 10px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 411px;
}

.content {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 126px;
  left: 30px;
  min-width: 356px;
  position: absolute;
  top: 30px;
}

.icon-2 {
  align-items: flex-start;
  background-color: var(--magic-potion);
  display: flex;
  min-width: 40px;
}

.overlap-group-2 {
  height: 40px;
  position: relative;
  width: 40px;
}

.shape {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
}

.shape-1 {
  height: 40px;
  left: 19px;
  position: absolute;
  top: 0;
  width: 21px;
}

.shape-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 19px;
  width: 40px;
}

.overlap-group-3 {
  height: 185px;
  margin-right: 6.0px;
  margin-top: 24px;
  position: relative;
  width: 416px;
}

.icon-3 {
  background-color: var(--sunglow);
  background-image: url(/static/img/shape-10.svg);
  background-size: 100% 100%;
  height: 40px;
  width: 40px;
}

.icon-4 {
  align-items: flex-start;
  background-color: var(--magic-potion);
  display: flex;
  height: 40px;
  min-width: 40px;
}

.shape-3 {
  background-color: var(--sea-serpent);
  height: 20px;
  width: 20px;
}

.shape-4 {
  align-self: flex-end;
  background-color: var(--sunglow);
  height: 20px;
  width: 20px;
}

.overlap-group1 {
  height: 185px;
  margin-left: 2.0px;
  margin-top: 180px;
  position: relative;
  width: 416px;
}

.bg-11,
.bg-13,
.bg-15,
.bg-23,
.bg-25,
.bg-27,
.bg-35,
.bg-37,
.bg-39 {
  background-color: var(--white);
  border: 3px solid;
  border-color: var(--magic-potion);
  border-radius: 10px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 411px;
}

.overlap-group1-1 {
  height: 185px;
  margin-left: 2.0px;
  margin-top: 24px;
  position: relative;
  width: 416px;
}

.overlap-group1-2 {
  height: 185px;
  margin-left: 6.0px;
  margin-top: 24px;
  position: relative;
  width: 416px;
}

.content-4 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 102px;
  left: 30px;
  min-width: 356px;
  position: absolute;
  top: 30px;
}

.icon-5 {
  align-items: flex-start;
  background-color: var(--princess-perfume);
  display: flex;
  min-width: 40px;
  transform: rotate(180deg);
}

.shape-5 {
  height: 40px;
  transform: rotate(-180deg);
  width: 40px;
}

.content-1 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 126px;
  left: 29px;
  min-width: 356px;
  position: absolute;
  top: 30px;
}

.content-5 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 126px;
  left: 29px;
  min-width: 362px;
  position: absolute;
  top: 30px;
}

.text-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 126px;
  width: 306px;
}

.cancellation-reschedule {
  letter-spacing: 0;
  line-height: 34px;
  white-space: nowrap;
}

.tutors-or-students-c {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 72px;
  width: 296px;
}

.icon-7 {
  align-items: flex-end;
  background-color: var(--magic-potion);
  display: flex;
  min-width: 40px;
  padding: 0 0.0px;
}

.overlap-group-6 {
  height: 40px;
  margin-bottom: 0;
  position: relative;
  width: 40px;
}

.shape-6 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.shape-7 {
  height: 26px;
  left: 19px;
  position: absolute;
  top: 14px;
  width: 21px;
}

.overlap-group4 {
  height: 185px;
  margin-right: 2.0px;
  margin-top: 24px;
  position: relative;
  width: 416px;
}

.ent-container {
  height: 701px;
  margin-right: -5px;
  margin-top: 87px;
  position: relative;
  width: 1600px;
}

.content-2-2 {
  align-items: center;
  background-color: #f8fafc;
  display: flex;
  gap: 31px;
  height: 701px;
  justify-content: flex-end;
  left: 0;
  min-width: 1600px;
  padding: 41px 173.0px;
  position: absolute;
  top: 0;
}

.content-left {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 523px;
  position: relative;
  width: 645px;
}

.deliver-a-learning-e {
  color: var(--eerie-black);
  font-family: var(--font-family-cabinet_grotesk-bold);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48px;
  margin-left: 5px;
  min-height: 144px;
  width: 545px;
}

.text-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 5px;
  margin-top: 24px;
  min-height: 79px;
  width: 585px;
}

.x1-2 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 418px;
}

.icon-6 {
  height: 20px;
  width: 20px;
}

.unlimited-diagnostic {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 384px;
  opacity: 0.7;
  white-space: nowrap;
}

.x2-2 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 587px;
  position: relative;
}

.x50000-practice-que {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 553px;
  opacity: 0.7;
  white-space: nowrap;
}

.text-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 3px;
  margin-top: 17px;
  min-height: 173px;
  width: 642px;
}

.x1-3 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  margin-left: 2px;
  min-width: 568px;
}

.daily-weekly-month {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 534px;
  opacity: 0.7;
  white-space: nowrap;
}

.x2-3 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  margin-left: 2px;
  min-width: 600px;
  position: relative;
}

.personalized-monitor {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 566px;
  opacity: 0.7;
  white-space: nowrap;
}

.x3-2 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 644px;
}

.ai-academic-advisor {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 610px;
  opacity: 0.7;
  white-space: nowrap;
}

.x4 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 346px;
  position: relative;
}

.guaranteed-score-increase-priceless {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 312px;
  opacity: 0.7;
  white-space: nowrap;
}

.overlap-group-7 {
  height: 419px;
  margin-bottom: 40.0px;
  position: relative;
  width: 494px;
}

.bg-40 {
  background-color: var(--white);
  border-radius: 6.63px;
  height: 419px;
  left: 0;
  position: absolute;
  top: 0;
  width: 424px;
}

.graph {
  background-color: var(--vulcan);
  height: 53px;
  left: 0;
  position: absolute;
  top: 0;
  width: 494px;
}

.blue-and-yellow-simp {
  height: 375px;
  left: 933px;
  position: absolute;
  top: 174px;
  width: 494px;
}

.ent-container-1 {
  height: 659px;
  margin-right: -5px;
  margin-top: 101px;
  position: relative;
  width: 1600px;
}

.content-3-1 {
  align-items: center;
  background-color: var(--white);
  display: flex;
  gap: 178px;
  height: 659px;
  justify-content: flex-end;
  left: 0;
  min-width: 1600px;
  padding: 0 39.0px;
  position: absolute;
  top: 0;
}

.overlap-group1-5 {
  height: 419px;
  position: relative;
  width: 529px;
}

.overlap-group-8 {
  height: 419px;
  left: 0;
  position: absolute;
  top: 0;
  width: 529px;
}

.bg-41 {
  background-color: var(--white);
  border-radius: 6.63px;
  height: 419px;
  left: 52px;
  position: absolute;
  top: 0;
  width: 424px;
}

.graph-1 {
  background-color: var(--vulcan);
  height: 53px;
  left: 0;
  position: absolute;
  top: 0;
  width: 529px;
}

.content-left-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 26px;
  min-height: 385px;
  position: relative;
  width: 644px;
}

.text-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 5.0px;
  min-height: 212px;
  width: 574px;
}

.handle-5000-the-amo {
  color: var(--eerie-black);
  font-family: var(--font-family-outfit);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48px;
  min-height: 96px;
  width: 568px;
}

.our-system-does-all {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 96px;
  opacity: 0.7;
  width: 570px;
}

.text-8 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 79px;
  width: 639px;
}

.x1-4 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 496px;
  position: relative;
}

.giving-you-a-persona {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 462px;
  opacity: 0.7;
  white-space: nowrap;
}

.x2-4 {
  align-items: center;
  display: flex;
  gap: 12px;
  height: 32px;
  min-width: 641px;
  position: relative;
}

.focus-on-hyper-scali {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 607px;
  opacity: 0.7;
  white-space: nowrap;
}

.blue-and-yellow-simp-1 {
  height: 356px;
  left: 209px;
  object-fit: cover;
  position: absolute;
  top: 173px;
  width: 530px;
}

.content-section-2 {
  align-items: flex-end;
  background-color: var(--bridal-heath);
  display: flex;
  height: 852px;
  justify-content: flex-end;
  min-width: 1600px;
  padding: 57.6px 57px;
}

.overlap-group1-6 {
  height: 734px;
  position: relative;
  width: 1391px;
}

.content-6 {
  align-items: flex-start;
  display: flex;
  height: 734px;
  left: 0;
  min-width: 1391px;
  position: absolute;
  top: 0;
}

.text-content-3 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: 674px;
  width: 636px;
}

.text-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 186px;
  width: 640px;
}

.foundational-5-step {
  letter-spacing: -1.00px;
  line-height: 54px;
  min-height: 108px;
  width: 636px;
}

.our-5-step-method-en {
  letter-spacing: 0;
  line-height: 30px;
  min-height: 60px;
  opacity: 0.8;
  width: 557px;
}

.text-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 438px;
  position: relative;
  width: 577px;
}

.line-24 {
  height: 1px;
  margin-top: 25px;
  object-fit: cover;
  width: 575px;
}

.line-245 {
  height: 1px;
  margin-top: 23px;
  object-fit: cover;
  width: 575px;
}

.flex-row-2 {
  align-items: center;
  display: flex;
  gap: 30px;
  height: 32px;
  margin-top: 24px;
  min-width: 566px;
}

.step-5-detailed-re {
  letter-spacing: -0.50px;
  line-height: 32px;
  min-width: 512px;
  white-space: nowrap;
}

.icon-outlinecheveron-down {
  height: 24px;
  margin-top: 0.79px;
  width: 24px;
}

.line-248 {
  height: 1px;
  margin-top: 27px;
  object-fit: cover;
  width: 575px;
}

.icon-outlinecheveron-down-1 {
  align-self: flex-end;
  height: 24px;
  margin-right: 11.1px;
  margin-top: 28px;
  width: 24px;
}

.overlap-group-9 {
  height: 615px;
  margin-top: 23.12px;
  position: relative;
  width: 755px;
}

.vector-2 {
  height: 393px;
  left: 0;
  position: absolute;
  top: 0;
  width: 419px;
}

.card-1 {
  background-color: var(--magic-potion);
  border-radius: 10.32px;
  height: 526px;
  left: 88px;
  position: absolute;
  top: 89px;
  width: 667px;
}

.blue-and-yellow-simp-2 {
  height: 498px;
  left: 724px;
  object-fit: cover;
  position: absolute;
  top: 126px;
  width: 667px;
}

.overlap-group23 {
  height: 816px;
  position: relative;
  width: 1600px;
}

.testimonial-section {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 80px;
  left: 0;
  min-height: 816px;
  padding: 120px 152px;
  position: absolute;
  top: 0;
  width: 1600px;
}

.what-our-past-parent {
  align-self: center;
  letter-spacing: -1.00px;
  line-height: 54px;
  min-height: 108px;
  text-align: center;
  width: 572px;
}

.x1-5 {
  align-items: center;
  background-color: var(--bridal-heath);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 592px;
  min-height: 388px;
  padding: 30px 0;
  position: absolute;
  top: 308px;
  width: 416px;
}

.sona-allowed-us-to {
  letter-spacing: 0;
  line-height: 28px;
  margin-right: 20.0px;
  min-height: 224px;
  width: 336px;
}

.img-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 100px;
  width: 356px;
}

.line-242 {
  height: 2px;
  margin-top: -2px;
  width: 356px;
}

.text-11 {
  align-items: center;
  display: flex;
  gap: 15px;
  min-width: 356px;
}

.image {
  height: 70px;
  width: 70px;
}

.text-12 {
  align-items: flex-end;
  display: flex;
  gap: 16px;
  height: 58px;
  margin-top: 4.0px;
  min-width: 275px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 58px;
  width: 147px;
}

.flex-col-item {
  letter-spacing: 0;
  line-height: 26px;
  white-space: nowrap;
}

.favourite-31 {
  height: 18px;
  margin-bottom: 4px;
  width: 108px;
}

.footer-section {
  align-items: flex-start;
  display: flex;
  height: 543px;
  min-width: 1600px;
}

.overlap-group-10 {
  background-color: var(--bridal-heath);
  background-size: 100% 100%;
  height: 536px;
  position: relative;
  width: 1600px;
}

.content-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 152px;
  min-height: 399px;
  position: absolute;
  top: 57px;
  width: 1296px;
}

.cta-text {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 167px;
  width: 626px;
}

.get-a-customized-ver {
  letter-spacing: -1.00px;
  line-height: 54px;
  min-height: 97px;
  text-align: center;
  width: 626px;
}

.we-pride-ourselves-i {
  letter-spacing: 0;
  line-height: 30px;
  min-height: 54px;
  opacity: 0.8;
  text-align: center;
  width: 558px;
}

.line-261-1 {
  height: 1px;
  margin-top: 169px;
  object-fit: cover;
  width: 1296px;
}

.copyright-2022-al {
  color: var(--magic-potion);
  font-family: var(--font-family-inter);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: -2px;
  margin-left: 1px;
  margin-top: 44px;
  min-height: 18px;
  text-align: right;
  white-space: nowrap;
  width: 500px;
}

