.icon-9 {
  align-items: flex-end;
  background-color: var(--magic-potion);
  display: flex;
  justify-content: flex-end;
  min-width: 40px;
}

.shape-8 {
  height: 40px;
  margin-bottom: 0;
  width: 20px;
}

.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  min-height: 40px;
  width: 20px;
}

.shape-9 {
  background-color: var(--sea-serpent);
  height: 20px;
  width: 20px;
}

.shape-10 {
  background-color: var(--sunglow);
  height: 20px;
  width: 20px;
}

